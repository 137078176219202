export const Transformers = {
    ObjectToWinnings: (payouts) => {
        const lines = Object.keys(payouts);
        let wins = [];
        lines.forEach((line) => {
            const symbols = Object.keys(payouts[line]);
            if (!symbols.length)
                return;
            symbols.forEach(symbol => {
                var _a, _b, _c, _d;
                wins.push({
                    line: line,
                    symbol: symbol,
                    // @ts-ignore
                    amount: (_b = (_a = payouts === null || payouts === void 0 ? void 0 : payouts[line]) === null || _a === void 0 ? void 0 : _a[symbol]) === null || _b === void 0 ? void 0 : _b.amount,
                    // @ts-ignore
                    count: (_d = (_c = payouts === null || payouts === void 0 ? void 0 : payouts[line]) === null || _c === void 0 ? void 0 : _c[symbol]) === null || _d === void 0 ? void 0 : _d.count
                });
            });
        });
        return wins;
    }
};
